@use "sass:color";
@use "./_variables.scss" as variables;
@use "./_mixins.scss" as mixins;

/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;
}

/**
 * Basic styling
 */
body {
    font-family: variables.$base-font-family;
    font-size: variables.$base-font-size;
    line-height: variables.$base-line-height;
    font-weight: 300;
    color: variables.$text-color;
    background-color: variables.$background-color;
    -webkit-text-size-adjust: 100%;
}



/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-bottom: variables.$spacing-unit-half;
}



/**
 * Images
 */
img {
    max-width: 100%;
    vertical-align: middle;
}



/**
 * Figures
 */
figure > img {
    display: block;
}

figcaption {
    font-size: variables.$small-font-size;
}



/**
 * Lists
 */
ul, ol {
    margin-left: variables.$spacing-unit;
}

li {
    > ul,
    > ol {
         margin-bottom: 0;
    }
}



/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
}



/**
 * Links
 */
a {
    color: variables.$brand-color;
    text-decoration: none;

    &:visited {
        color: color.scale(variables.$brand-color, $lightness: -25%);
    }

    &:hover {
        color: variables.$text-color;
        text-decoration: underline;
    }
}



/**
 * Blockquotes
 */
blockquote {
    color: variables.$grey-color;
    border-left: 4px solid variables.$grey-color-light;
    padding-left: variables.$spacing-unit-half;
    font-size: 18px;

    > :last-child {
        margin-bottom: 0;
    }
}



/**
 * Code formatting
 */
pre,
code {
    font-size: 15px;
    border: 1px solid variables.$grey-color-light;
    border-radius: 3px;
    background-color: #f5f5f5;
}

code {
    padding: 1px 5px;
}

pre {
    padding: 8px 12px;
    overflow-x: scroll;

    > code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
    }
}



/**
 * Wrapper
 */
.wrapper {
    max-width: -webkit-calc(800px - (#{variables.$spacing-unit} * 2));
    max-width:         calc(800px - (#{variables.$spacing-unit} * 2));
    margin-right: auto;
    margin-left: auto;
    padding-right: variables.$spacing-unit;
    padding-left: variables.$spacing-unit;
    @extend %clearfix;

    @include mixins.media-query(variables.$on-laptop) {
        max-width: -webkit-calc(800px - (#{variables.$spacing-unit}));
        max-width:         calc(800px - (#{variables.$spacing-unit}));
        padding-right: variables.$spacing-unit-half;
        padding-left: variables.$spacing-unit-half;
    }
}



/**
 * Clearfix
 */
%clearfix {

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}



/**
 * Icons
 */
.icon {

    > svg {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;

        path {
            fill: variables.$grey-color;
        }
    }
}

/**
 * Spoiler Tag
 */
.spoiler {
    color: variables.$background-color;
}

/**
 * Faint Grey Color
 */
.faint {
    color: variables.$grey-color-light;
}

/**
 * Ruby Link Color
 */
.ruby {
  color: variables.$ruby-color;
}

a {
    color: variables.$ruby-color;
    text-decoration: none;
    font-weight: 400;

    &:hover {
        text-decoration: underline;
    }

    &:visited {
        color: variables.$ruby-color;
        text-decoration: none;
    }
}

/**
 * Buttons - Copied from SimpleCSS
 */
a button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  border: none;
  border-radius: 5px;
  background-color: variables.$ruby-color;
  font-size: 1rem;
  color: #fff;
  padding: .7rem .9rem;
  margin: .5rem 0;
  transition: .4s;
}

a button[disabled],
button[disabled],
input[type="submit"][disabled],
input[type="reset"][disabled],
input[type="button"][disabled],
input[type="checkbox"][disabled],
input[type="radio"][disabled],
select[disabled] {
  cursor: default;
  opacity: .5;
  cursor: not-allowed;
}

button:focus,
button:enabled:hover,
input[type="submit"]:focus,
input[type="submit"]:enabled:hover,
input[type="reset"]:focus,
input[type="reset"]:enabled:hover,
input[type="button"]:focus,
input[type="button"]:enabled:hover,
input[type="checkbox"]:focus,
input[type="checkbox"]:enabled:hover,
input[type="radio"]:focus,
input[type="radio"]:enabled:hover{
  opacity: .8;
  cursor: pointer;
}
