@use "./_variables.scss" as variables;
@use "./_mixins.scss" as mixins;
@forward "./_base.scss";

/**
 * Site header
 */
.site-header {
    border-top: 5px solid variables.$grey-color-dark;
    border-bottom: 1px solid variables.$grey-color-light;
    min-height: 56px;

    // Positioning context for the mobile navigation icon
    position: relative;
}

.site-title {
    font-size: 26px;
    line-height: 56px;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;

    &,
    &:visited {
        color: variables.$grey-color-dark;
    }
}

.site-nav {
    float: right;
    line-height: 56px;

    .menu-icon {
        display: none;
    }

    .page-link {
        // color: $text-color;
        color: variables.$ruby-color;
        line-height: variables.$base-line-height;
        font-size: 18px;
        // font-weight: bold;

        // Gaps between nav items, but not on the first one
        &:not(:first-child) {
            margin-left: 20px;
        }
    }

    @include mixins.media-query(variables.$on-palm) {
        position: absolute;
        top: 9px;
        right: 30px;
        background-color: variables.$background-color;
        border: 1px solid variables.$grey-color-light;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: variables.$grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: block;
            padding: 5px 10px;
        }
    }
}



/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid variables.$grey-color-light;
    padding: variables.$spacing-unit 0;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: variables.$spacing-unit-half;
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 15px;
    color: variables.$grey-color;
    margin-left: -#{variables.$spacing-unit-half};
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: variables.$spacing-unit-half;
    padding-left: variables.$spacing-unit-half;
}

.footer-col-1 {
    width: -webkit-calc(35% - #{variables.$spacing-unit-half});
    width:         calc(35% - #{variables.$spacing-unit-half});
}

.footer-col-2 {
    width: -webkit-calc(20% - #{variables.$spacing-unit-half});
    width:         calc(20% - #{variables.$spacing-unit-half});
}

.footer-col-3 {
    width: -webkit-calc(45% - #{variables.$spacing-unit-half});
    width:         calc(45% - #{variables.$spacing-unit-half});
}

@include mixins.media-query(variables.$on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - #{variables.$spacing-unit-half});
        width:         calc(50% - #{variables.$spacing-unit-half});
    }

    .footer-col-3 {
        width: -webkit-calc(100% - #{variables.$spacing-unit-half});
        width:         calc(100% - #{variables.$spacing-unit-half});
    }
}

@include mixins.media-query(variables.$on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - #{variables.$spacing-unit-half});
        width:         calc(100% - #{variables.$spacing-unit-half});
    }
}



/**
 * Page content
 */
.page-content {
    padding: variables.$spacing-unit 0;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: variables.$spacing-unit;
    }
}

.post-meta {
    font-size: variables.$small-font-size;
    color: variables.$grey-color;
}

.post-link {
    display: block;
    font-size: 24px;
}

/**
 * Posts
 */
.post-header {
    margin-bottom: variables.$spacing-unit-half;

    #last-modified {
        font-size: variables.$base-font-size - 2;
        color: #828282;
    }
}

.post-title {
    font-size: 32px;
    letter-spacing: -1px;
    line-height: 1;

    @include mixins.media-query(variables.$on-laptop) {
        font-size: 28px;
    }
}

.post-content {
    margin-bottom: variables.$spacing-unit;

    h2 {
        font-size: 24px;

        @include mixins.media-query(variables.$on-laptop) {
            font-size: 22px;
        }
    }

    h3 {
        font-size: 22px;

        @include mixins.media-query(variables.$on-laptop) {
            font-size: 18px;
        }
    }

    h4 {
        font-size: 20px;

        @include mixins.media-query(variables.$on-laptop) {
            font-size: 16px;
        }
    }
}

.games-nav {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      font-size: 16px;
      overflow: hidden;

      li {
        float: left;
        margin-right: 2px;
        color: #000;

        a {
          display: block;
          padding: 2px 4px 2px 4px;
          text-decoration: none;
          color: #fff;
          /* background-color: #333; */
        }

        a:hover {
          background-color: transparent;
        }
        
        a:active {
          background-color: transparent;
        }
      }
    }
  }
